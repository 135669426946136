import { useCallback, useEffect, useRef } from "react"

export function useMounted(): () => boolean {
    const mountedRef = useRef<boolean>(false)
    const get = useCallback(() => mountedRef.current, [])

    useEffect(() => {
        mountedRef.current = true

        return () => {
            mountedRef.current = false
        }
    }, [])

    return get
}
