export interface HiveHexLocation {
    x: number
    y: number
    yaw: number
    half?: boolean
}

// Use to convert HexID to Game Location
export const HiveHexLocations: HiveHexLocation[] = [
    { x: -1191, y: -22049, yaw: 0, half: true },
    { x: 118, y: -24317, yaw: 60, half: true },
    { x: 4048, y: -26585, yaw: 60, half: true },
    { x: 6666, y: -26586, yaw: 120, half: true },
    { x: 10579, y: -24327, yaw: 120, half: true },
    { x: 11901, y: -22052, yaw: 180, half: true },
    { x: 11905, y: -17546, yaw: 180, half: true },
    { x: 10597, y: -15251, yaw: 240, half: true },
    { x: 122, y: -15248, yaw: -60, half: true },
    { x: -1188, y: -17514, yaw: 0, half: true },
    { x: 6650, y: -13012, yaw: 240, half: true },
    { x: 7976, y: -19785, yaw: 90 },
    { x: 2737, y: -24318, yaw: 90 },
    { x: 5356, y: -24318, yaw: 90 },
    { x: 4048, y: -22051, yaw: 90 },
    { x: 7975, y: -15252, yaw: 90 },
    { x: 4010, y: -13007, yaw: -60, half: true },
    { x: 10593, y: -19786, yaw: 90 },
    { x: 6666, y: -22051, yaw: 150 },
    { x: 7973, y: -24318, yaw: 150 },
    { x: 9283, y: -22052, yaw: 90 },
    { x: 2739, y: -15249, yaw: 90 },
    { x: 4049, y: -17516, yaw: 90 },
    { x: 6667, y: -17518, yaw: 90 },
    { x: 5357, y: -19785, yaw: 150 },
    { x: 5358, y: -15250, yaw: 90 },
    { x: 9286, y: -17518, yaw: 90 },
    { x: 1430, y: -17515, yaw: 90 },
    { x: 118, y: -19781, yaw: 150 },
    { x: 1429, y: -22049, yaw: 90 },
    { x: 2739, y: -19782, yaw: 150 },
    { x: 14798, y: 10140, yaw: -60, half: true },
    { x: 13489, y: 7872, yaw: 0, half: true },
    { x: 13489, y: 3335, yaw: 0, half: true },
    { x: 14797, y: 1067, yaw: 60, half: true },
    { x: 18710, y: -1192, yaw: 60, half: true },
    { x: 21342, y: -1200, yaw: 120, half: true },
    { x: 25246, y: 1051, yaw: 120, half: true },
    { x: 26580, y: 3330, yaw: 180, half: true },
    { x: 21345, y: 12403, yaw: 240, half: true },
    { x: 18728, y: 12405, yaw: -60, half: true },
    { x: 26545, y: 7868, yaw: 180, half: true },
    { x: 21342, y: 3333, yaw: 30 },
    { x: 14797, y: 5604, yaw: 30 },
    { x: 16107, y: 3335, yaw: 30 },
    { x: 17416, y: 5602, yaw: 30 },
    { x: 25268, y: 5600, yaw: 30 },
    { x: 25230, y: 10157, yaw: 240, half: true },
    { x: 22650, y: 1067, yaw: 30 },
    { x: 18725, y: 3334, yaw: 90 },
    { x: 17415, y: 1069, yaw: 90 },
    { x: 20033, y: 1068, yaw: 30 },
    { x: 22652, y: 10137, yaw: 30 },
    { x: 21344, y: 7869, yaw: 30 },
    { x: 22651, y: 5600, yaw: 30 },
    { x: 20034, y: 5601, yaw: 90 },
    { x: 23961, y: 7868, yaw: 30 },
    { x: 23961, y: 3332, yaw: 30 },
    { x: 20036, y: 10137, yaw: 30 },
    { x: 17417, y: 10140, yaw: 90 },
    { x: 16109, y: 7871, yaw: 30 },
    { x: 18726, y: 7870, yaw: 90 },
    { x: 36775, y: -27925, yaw: -60, half: true },
    { x: 35465, y: -30193, yaw: 0, half: true },
    { x: 35466, y: -34730, yaw: 0, half: true },
    { x: 36774, y: -36997, yaw: 60, half: true },
    { x: 40687, y: -39257, yaw: 60, half: true },
    { x: 43318, y: -39265, yaw: 120, half: true },
    { x: 47223, y: -37014, yaw: 120, half: true },
    { x: 48556, y: -34735, yaw: 180, half: true },
    { x: 43321, y: -25662, yaw: 240, half: true },
    { x: 40705, y: -25660, yaw: -60, half: true },
    { x: 48522, y: -30197, yaw: 180, half: true },
    { x: 43319, y: -34732, yaw: 30 },
    { x: 36774, y: -32461, yaw: 30 },
    { x: 38084, y: -34729, yaw: 30 },
    { x: 39393, y: -32462, yaw: 30 },
    { x: 47244, y: -32465, yaw: 30 },
    { x: 47207, y: -27908, yaw: 240, half: true },
    { x: 44627, y: -36998, yaw: 30 },
    { x: 40702, y: -34731, yaw: 90 },
    { x: 39392, y: -36996, yaw: 90 },
    { x: 42010, y: -36997, yaw: 30 },
    { x: 44629, y: -27928, yaw: 30 },
    { x: 43320, y: -30196, yaw: 30 },
    { x: 44628, y: -32464, yaw: 30 },
    { x: 42010, y: -32464, yaw: 90 },
    { x: 45938, y: -30197, yaw: 30 },
    { x: 45937, y: -34732, yaw: 30 },
    { x: 42012, y: -27928, yaw: 30 },
    { x: 39393, y: -27925, yaw: 90 },
    { x: 38085, y: -30194, yaw: 30 },
    { x: 40703, y: -30195, yaw: 90 },
    { x: 11371, y: -39278, yaw: 60, half: true },
    { x: 13990, y: -39278, yaw: 120, half: true },
    { x: 17918, y: -37009, yaw: 120, half: true },
    { x: 19228, y: -34742, yaw: 180, half: true },
    { x: 19228, y: -30224, yaw: 180, half: true },
    { x: 17920, y: -27941, yaw: 240, half: true },
    { x: 14018, y: -25685, yaw: 240, half: true },
    { x: 11378, y: -25670, yaw: -60, half: true },
    { x: 6138, y: -34740, yaw: 0, half: true },
    { x: 7445, y: -37007, yaw: 60, half: true },
    { x: 7465, y: -27969, yaw: -60, half: true },
    { x: 13994, y: -30207, yaw: 150 },
    { x: 15300, y: -37011, yaw: 150 },
    { x: 16609, y: -34742, yaw: 150 },
    { x: 13992, y: -34742, yaw: 150 },
    { x: 10068, y: -27941, yaw: 150 },
    { x: 6140, y: -30252, yaw: 0, half: true },
    { x: 15302, y: -27941, yaw: 150 },
    { x: 15301, y: -32474, yaw: 210 },
    { x: 17918, y: -32476, yaw: 210 },
    { x: 16610, y: -30208, yaw: 150 },
    { x: 7447, y: -32474, yaw: 150 },
    { x: 10065, y: -32474, yaw: 150 },
    { x: 11376, y: -30207, yaw: 150 },
    { x: 12684, y: -32475, yaw: 210 },
    { x: 8757, y: -30206, yaw: 150 },
    { x: 12685, y: -27939, yaw: 150 },
    { x: 8755, y: -34741, yaw: 150 },
    { x: 10061, y: -37010, yaw: 210 },
    { x: 12680, y: -37009, yaw: 150 },
    { x: 11373, y: -34741, yaw: 210 },
    { x: 7490, y: -2556, yaw: -60, half: true },
    { x: 6181, y: -4824, yaw: 0, half: true },
    { x: 6182, y: -9361, yaw: 0, half: true },
    { x: 7489, y: -11628, yaw: 60, half: true },
    { x: 11403, y: -13888, yaw: 60, half: true },
    { x: 14034, y: -13896, yaw: 120, half: true },
    { x: 17938, y: -11645, yaw: 120, half: true },
    { x: 19272, y: -9366, yaw: 180, half: true },
    { x: 14037, y: -293, yaw: 240, half: true },
    { x: 11420, y: -291, yaw: -60, half: true },
    { x: 19237, y: -4828, yaw: 180, half: true },
    { x: 14034, y: -9363, yaw: 30 },
    { x: 7489, y: -7092, yaw: 30 },
    { x: 8800, y: -9360, yaw: 30 },
    { x: 10108, y: -7093, yaw: 30 },
    { x: 17960, y: -7096, yaw: 30 },
    { x: 17922, y: -2539, yaw: 240, half: true },
    { x: 15342, y: -11629, yaw: 30 },
    { x: 11417, y: -9362, yaw: 90 },
    { x: 10107, y: -11627, yaw: 90 },
    { x: 12725, y: -11628, yaw: 30 },
    { x: 15344, y: -2559, yaw: 30 },
    { x: 14036, y: -4827, yaw: 30 },
    { x: 15343, y: -7095, yaw: 30 },
    { x: 12726, y: -7095, yaw: 90 },
    { x: 16653, y: -4828, yaw: 30 },
    { x: 16653, y: -9363, yaw: 30 },
    { x: 12728, y: -2559, yaw: 30 },
    { x: 10109, y: -2556, yaw: 90 },
    { x: 8801, y: -4825, yaw: 30 },
    { x: 11418, y: -4826, yaw: 90 },
    { x: 164, y: 10135, yaw: -60, half: true },
    { x: -1146, y: 7867, yaw: 0, half: true },
    { x: -1145, y: 3330, yaw: 0, half: true },
    { x: 163, y: 1062, yaw: 60, half: true },
    { x: 4076, y: -1197, yaw: 60, half: true },
    { x: 6707, y: -1205, yaw: 120, half: true },
    { x: 10612, y: 1045, yaw: 120, half: true },
    { x: 11945, y: 3325, yaw: 180, half: true },
    { x: 6710, y: 12398, yaw: 240, half: true },
    { x: 4093, y: 12400, yaw: -60, half: true },
    { x: 11911, y: 7862, yaw: 180, half: true },
    { x: 6708, y: 3328, yaw: 30 },
    { x: 163, y: 5599, yaw: 30 },
    { x: 1473, y: 3330, yaw: 30 },
    { x: 2781, y: 5597, yaw: 30 },
    { x: 10633, y: 5595, yaw: 30 },
    { x: 10595, y: 10151, yaw: 240, half: true },
    { x: 8016, y: 1061, yaw: 30 },
    { x: 4090, y: 3329, yaw: 90 },
    { x: 2781, y: 1064, yaw: 90 },
    { x: 5398, y: 1062, yaw: 30 },
    { x: 8018, y: 10131, yaw: 30 },
    { x: 6709, y: 7863, yaw: 30 },
    { x: 8017, y: 5595, yaw: 30 },
    { x: 5399, y: 5596, yaw: 90 },
    { x: 9327, y: 7862, yaw: 30 },
    { x: 9326, y: 3327, yaw: 30 },
    { x: 5401, y: 10132, yaw: 30 },
    { x: 2782, y: 10135, yaw: 90 },
    { x: 1474, y: 7866, yaw: 30 },
    { x: 4092, y: 7865, yaw: 90 },
    { x: 41232, y: -17509, yaw: 180, half: true },
    { x: 39922, y: -15241, yaw: 240, half: true },
    { x: 35993, y: -12973, yaw: 240, half: true },
    { x: 33375, y: -12972, yaw: -60, half: true },
    { x: 29462, y: -15231, yaw: -60, half: true },
    { x: 28139, y: -17505, yaw: 0, half: true },
    { x: 28136, y: -22012, yaw: 0, half: true },
    { x: 29444, y: -24307, yaw: 60, half: true },
    { x: 39918, y: -24309, yaw: 120, half: true },
    { x: 41228, y: -22044, yaw: 180, half: true },
    { x: 33390, y: -26546, yaw: 60, half: true },
    { x: 32065, y: -19772, yaw: -90 },
    { x: 37304, y: -15240, yaw: -90 },
    { x: 34684, y: -15240, yaw: -90 },
    { x: 35993, y: -17507, yaw: -90 },
    { x: 32065, y: -24306, yaw: -90 },
    { x: 36031, y: -26551, yaw: 120, half: true },
    { x: 29448, y: -19772, yaw: -90 },
    { x: 33374, y: -17506, yaw: -30 },
    { x: 32068, y: -15240, yaw: -30 },
    { x: 30758, y: -17506, yaw: -90 },
    { x: 37302, y: -24309, yaw: -90 },
    { x: 35992, y: -22042, yaw: -90 },
    { x: 33374, y: -22040, yaw: -90 },
    { x: 34683, y: -19773, yaw: -30 },
    { x: 34682, y: -24308, yaw: -90 },
    { x: 30755, y: -22040, yaw: -90 },
    { x: 38610, y: -22043, yaw: -90 },
    { x: 39923, y: -19777, yaw: -30 },
    { x: 38612, y: -17509, yaw: -90 },
    { x: 37302, y: -19775, yaw: -30 },
    { x: 29467, y: -40620, yaw: -60, half: true },
    { x: 28158, y: -42889, yaw: 0, half: true },
    { x: 28158, y: -47425, yaw: 0, half: true },
    { x: 29466, y: -49693, yaw: 60, half: true },
    { x: 33379, y: -51952, yaw: 60, half: true },
    { x: 36011, y: -51961, yaw: 120, half: true },
    { x: 39915, y: -49710, yaw: 120, half: true },
    { x: 41248, y: -47431, yaw: 180, half: true },
    { x: 36013, y: -38358, yaw: 240, half: true },
    { x: 33397, y: -38356, yaw: -60, half: true },
    { x: 41214, y: -42893, yaw: 180, half: true },
    { x: 36011, y: -47428, yaw: 30 },
    { x: 29466, y: -45157, yaw: 30 },
    { x: 30776, y: -47425, yaw: 30 },
    { x: 32085, y: -45158, yaw: 30 },
    { x: 39937, y: -45161, yaw: 30 },
    { x: 39899, y: -40604, yaw: 240, half: true },
    { x: 37319, y: -49694, yaw: 30 },
    { x: 33394, y: -47426, yaw: 90 },
    { x: 32084, y: -49691, yaw: 90 },
    { x: 34702, y: -49693, yaw: 30 },
    { x: 37321, y: -40624, yaw: 30 },
    { x: 36012, y: -42892, yaw: 30 },
    { x: 37320, y: -45160, yaw: 30 },
    { x: 34702, y: -45159, yaw: 90 },
    { x: 38630, y: -42893, yaw: 30 },
    { x: 38630, y: -47428, yaw: 30 },
    { x: 34705, y: -40624, yaw: 30 },
    { x: 32086, y: -40620, yaw: 90 },
    { x: 30778, y: -42889, yaw: 30 },
    { x: 33395, y: -42891, yaw: 90 },
    { x: 32611, y: -37008, yaw: 120, half: true },
    { x: 33920, y: -34740, yaw: 180, half: true },
    { x: 33920, y: -30203, yaw: 180, half: true },
    { x: 32612, y: -27935, yaw: 240, half: true },
    { x: 28699, y: -25676, yaw: 240, half: true },
    { x: 26067, y: -25668, yaw: -60, half: true },
    { x: 22163, y: -27918, yaw: -60, half: true },
    { x: 20830, y: -30198, yaw: 0, half: true },
    { x: 26065, y: -39271, yaw: 60, half: true },
    { x: 28681, y: -39273, yaw: 120, half: true },
    { x: 20864, y: -34735, yaw: 0, half: true },
    { x: 26067, y: -30201, yaw: 210 },
    { x: 32612, y: -32472, yaw: 210 },
    { x: 31302, y: -30203, yaw: 210 },
    { x: 29993, y: -32470, yaw: 210 },
    { x: 22141, y: -32468, yaw: 210 },
    { x: 22179, y: -37025, yaw: 60, half: true },
    { x: 24759, y: -27935, yaw: 210 },
    { x: 28684, y: -30202, yaw: -90 },
    { x: 29994, y: -27937, yaw: -90 },
    { x: 27376, y: -27935, yaw: 210 },
    { x: 24757, y: -37004, yaw: 210 },
    { x: 26065, y: -34737, yaw: 210 },
    { x: 24758, y: -32468, yaw: 210 },
    { x: 27376, y: -32469, yaw: -90 },
    { x: 23448, y: -34736, yaw: 210 },
    { x: 23448, y: -30200, yaw: 210 },
    { x: 27373, y: -37005, yaw: 210 },
    { x: 29992, y: -37008, yaw: -90 },
    { x: 31300, y: -34739, yaw: 210 },
    { x: 28683, y: -34738, yaw: -90 },
    { x: 28670, y: -280, yaw: 240, half: true },
    { x: 26051, y: -280, yaw: -60, half: true },
    { x: 22122, y: -2549, yaw: -60, half: true },
    { x: 20812, y: -4815, yaw: 0, half: true },
    { x: 20812, y: -9334, yaw: 0, half: true },
    { x: 22121, y: -11617, yaw: 60, half: true },
    { x: 26022, y: -13873, yaw: 60, half: true },
    { x: 28663, y: -13888, yaw: 120, half: true },
    { x: 33903, y: -4818, yaw: 180, half: true },
    { x: 32596, y: -2551, yaw: 240, half: true },
    { x: 32576, y: -11589, yaw: 120, half: true },
    { x: 26047, y: -9351, yaw: -30 },
    { x: 24741, y: -2547, yaw: -30 },
    { x: 23432, y: -4816, yaw: -30 },
    { x: 26049, y: -4816, yaw: -30 },
    { x: 29973, y: -11617, yaw: -30 },
    { x: 33900, y: -9306, yaw: 180, half: true },
    { x: 24738, y: -11616, yaw: -30 },
    { x: 24739, y: -7083, yaw: 30 },
    { x: 22123, y: -7082, yaw: 30 },
    { x: 23430, y: -9349, yaw: -30 },
    { x: 32594, y: -7083, yaw: -30 },
    { x: 29976, y: -7084, yaw: -30 },
    { x: 28665, y: -9351, yaw: -30 },
    { x: 27357, y: -7083, yaw: 30 },
    { x: 31284, y: -9351, yaw: -30 },
    { x: 27356, y: -11619, yaw: -30 },
    { x: 31286, y: -4817, yaw: -30 },
    { x: 29979, y: -2548, yaw: 30 },
    { x: 27360, y: -2549, yaw: -30 },
    { x: 28668, y: -4817, yaw: 30 },
    { x: 44092, y: -15229, yaw: -60, half: true },
    { x: 42782, y: -17497, yaw: 0, half: true },
    { x: 42783, y: -22034, yaw: 0, half: true },
    { x: 44091, y: -24301, yaw: 60, half: true },
    { x: 48004, y: -26561, yaw: 60, half: true },
    { x: 50635, y: -26569, yaw: 120, half: true },
    { x: 54540, y: -24318, yaw: 120, half: true },
    { x: 55873, y: -22039, yaw: 180, half: true },
    { x: 50638, y: -12966, yaw: 240, half: true },
    { x: 48021, y: -12964, yaw: -60, half: true },
    { x: 55839, y: -17501, yaw: 180, half: true },
    { x: 50636, y: -22036, yaw: 30 },
    { x: 44091, y: -19765, yaw: 30 },
    { x: 45401, y: -22033, yaw: 30 },
    { x: 46709, y: -19766, yaw: 30 },
    { x: 54561, y: -19769, yaw: 30 },
    { x: 54523, y: -15212, yaw: 240, half: true },
    { x: 51944, y: -24302, yaw: 30 },
    { x: 48018, y: -22035, yaw: 90 },
    { x: 46709, y: -24300, yaw: 90 },
    { x: 49326, y: -24301, yaw: 30 },
    { x: 51946, y: -15232, yaw: 30 },
    { x: 50637, y: -17500, yaw: 30 },
    { x: 51945, y: -19768, yaw: 30 },
    { x: 49327, y: -19768, yaw: 90 },
    { x: 53255, y: -17501, yaw: 30 },
    { x: 53254, y: -22036, yaw: 30 },
    { x: 49329, y: -15232, yaw: 30 },
    { x: 46710, y: -15229, yaw: 90 },
    { x: 45402, y: -17498, yaw: 30 },
    { x: 48020, y: -17499, yaw: 90 },
    { x: 36765, y: -2538, yaw: -60, half: true },
    { x: 35455, y: -4806, yaw: 0, half: true },
    { x: 35456, y: -9343, yaw: 0, half: true },
    { x: 36764, y: -11611, yaw: 60, half: true },
    { x: 40677, y: -13870, yaw: 60, half: true },
    { x: 43308, y: -13878, yaw: 120, half: true },
    { x: 47213, y: -11628, yaw: 120, half: true },
    { x: 48546, y: -9348, yaw: 180, half: true },
    { x: 43311, y: -275, yaw: 240, half: true },
    { x: 40694, y: -273, yaw: -60, half: true },
    { x: 48512, y: -4811, yaw: 180, half: true },
    { x: 43309, y: -9345, yaw: 30 },
    { x: 36764, y: -7074, yaw: 30 },
    { x: 38074, y: -9343, yaw: 30 },
    { x: 39383, y: -7076, yaw: 30 },
    { x: 47234, y: -7078, yaw: 30 },
    { x: 47196, y: -2521, yaw: 240, half: true },
    { x: 44617, y: -11612, yaw: 30 },
    { x: 40691, y: -9344, yaw: 90 },
    { x: 39382, y: -11609, yaw: 90 },
    { x: 41999, y: -11611, yaw: 30 },
    { x: 44619, y: -2542, yaw: 30 },
    { x: 43310, y: -4810, yaw: 30 },
    { x: 44618, y: -7078, yaw: 30 },
    { x: 42000, y: -7077, yaw: 90 },
    { x: 45928, y: -4811, yaw: 30 },
    { x: 45927, y: -9346, yaw: 30 },
    { x: 42002, y: -2541, yaw: 30 },
    { x: 39383, y: -2538, yaw: 90 },
    { x: 38075, y: -4807, yaw: 30 },
    { x: 40693, y: -4808, yaw: 90 },
    { x: 29438, y: 10152, yaw: -60, half: true },
    { x: 28129, y: 7884, yaw: 0, half: true },
    { x: 28130, y: 3347, yaw: 0, half: true },
    { x: 29437, y: 1079, yaw: 60, half: true },
    { x: 33350, y: -1180, yaw: 60, half: true },
    { x: 35982, y: -1188, yaw: 120, half: true },
    { x: 39886, y: 1062, yaw: 120, half: true },
    { x: 41220, y: 3342, yaw: 180, half: true },
    { x: 35985, y: 12414, yaw: 240, half: true },
    { x: 33368, y: 12416, yaw: -60, half: true },
    { x: 41185, y: 7879, yaw: 180, half: true },
    { x: 35982, y: 3345, yaw: 30 },
    { x: 29437, y: 5616, yaw: 30 },
    { x: 30747, y: 3347, yaw: 30 },
    { x: 32056, y: 5614, yaw: 30 },
    { x: 39908, y: 5612, yaw: 30 },
    { x: 39870, y: 10168, yaw: 240, half: true },
    { x: 37290, y: 1078, yaw: 30 },
    { x: 33365, y: 3346, yaw: 90 },
    { x: 32055, y: 1081, yaw: 90 },
    { x: 34673, y: 1079, yaw: 30 },
    { x: 37292, y: 10148, yaw: 30 },
    { x: 35984, y: 7880, yaw: 30 },
    { x: 37291, y: 5612, yaw: 30 },
    { x: 34674, y: 5613, yaw: 90 },
    { x: 38601, y: 7879, yaw: 30 },
    { x: 38601, y: 3344, yaw: 30 },
    { x: 34676, y: 10149, yaw: 30 },
    { x: 32057, y: 10152, yaw: 90 },
    { x: 30749, y: 7883, yaw: 30 },
    { x: 33366, y: 7882, yaw: 90 },
    { x: 25291, y: -24329, yaw: 120, half: true },
    { x: 26600, y: -22061, yaw: 180, half: true },
    { x: 26599, y: -17524, yaw: 180, half: true },
    { x: 25292, y: -15256, yaw: 240, half: true },
    { x: 21379, y: -12997, yaw: 240, half: true },
    { x: 18747, y: -12989, yaw: -60, half: true },
    { x: 14843, y: -15240, yaw: -60, half: true },
    { x: 13509, y: -17519, yaw: 0, half: true },
    { x: 18744, y: -26592, yaw: 60, half: true },
    { x: 21361, y: -26594, yaw: 120, half: true },
    { x: 13544, y: -22057, yaw: 0, half: true },
    { x: 18747, y: -17522, yaw: 210 },
    { x: 25292, y: -19793, yaw: 210 },
    { x: 23982, y: -17524, yaw: 210 },
    { x: 22673, y: -19791, yaw: 210 },
    { x: 14821, y: -19789, yaw: 210 },
    { x: 14859, y: -24346, yaw: 60, half: true },
    { x: 17439, y: -15256, yaw: 210 },
    { x: 21364, y: -17523, yaw: -90 },
    { x: 22674, y: -15258, yaw: -90 },
    { x: 20056, y: -15257, yaw: 210 },
    { x: 17437, y: -24326, yaw: 210 },
    { x: 18745, y: -22058, yaw: 210 },
    { x: 17438, y: -19789, yaw: 210 },
    { x: 20055, y: -19790, yaw: -90 },
    { x: 16128, y: -22057, yaw: 210 },
    { x: 16128, y: -17521, yaw: 210 },
    { x: 20053, y: -24326, yaw: 210 },
    { x: 22672, y: -24329, yaw: -90 },
    { x: 23980, y: -22060, yaw: 210 },
    { x: 21363, y: -22059, yaw: -90 },
    { x: 14775, y: -40626, yaw: -60, half: true },
    { x: 13466, y: -42894, yaw: 0, half: true },
    { x: 13467, y: -47431, yaw: 0, half: true },
    { x: 14774, y: -49699, yaw: 60, half: true },
    { x: 18688, y: -51958, yaw: 60, half: true },
    { x: 21319, y: -51966, yaw: 120, half: true },
    { x: 25223, y: -49716, yaw: 120, half: true },
    { x: 26557, y: -47436, yaw: 180, half: true },
    { x: 21322, y: -38363, yaw: 240, half: true },
    { x: 18705, y: -38361, yaw: -60, half: true },
    { x: 26522, y: -42899, yaw: 180, half: true },
    { x: 21319, y: -47433, yaw: 30 },
    { x: 14774, y: -45162, yaw: 30 },
    { x: 16085, y: -47431, yaw: 30 },
    { x: 17393, y: -45164, yaw: 30 },
    { x: 25245, y: -45166, yaw: 30 },
    { x: 25207, y: -40609, yaw: 240, half: true },
    { x: 22627, y: -49699, yaw: 30 },
    { x: 18702, y: -47432, yaw: 90 },
    { x: 17392, y: -49697, yaw: 90 },
    { x: 20010, y: -49699, yaw: 30 },
    { x: 22629, y: -40630, yaw: 30 },
    { x: 21321, y: -42897, yaw: 30 },
    { x: 22628, y: -45166, yaw: 30 },
    { x: 20011, y: -45165, yaw: 90 },
    { x: 23938, y: -42898, yaw: 30 },
    { x: 23938, y: -47434, yaw: 30 },
    { x: 20013, y: -40629, yaw: 30 },
    { x: 17394, y: -40626, yaw: 90 },
    { x: 16086, y: -42895, yaw: 30 },
    { x: 18703, y: -42896, yaw: 90 },
    { x: -7168, y: -27903, yaw: -60, half: true },
    { x: -8478, y: -30171, yaw: 0, half: true },
    { x: -8477, y: -34708, yaw: 0, half: true },
    { x: -7169, y: -36976, yaw: 60, half: true },
    { x: -3256, y: -39235, yaw: 60, half: true },
    { x: -625, y: -39243, yaw: 120, half: true },
    { x: 3280, y: -36993, yaw: 120, half: true },
    { x: 4613, y: -34713, yaw: 180, half: true },
    { x: -622, y: -25640, yaw: 240, half: true },
    { x: -3239, y: -25638, yaw: -60, half: true },
    { x: 4579, y: -30176, yaw: 180, half: true },
    { x: -624, y: -34710, yaw: 30 },
    { x: -7169, y: -32439, yaw: 30 },
    { x: -5859, y: -34708, yaw: 30 },
    { x: -4551, y: -32441, yaw: 30 },
    { x: 3301, y: -32443, yaw: 30 },
    { x: 3263, y: -27886, yaw: 240, half: true },
    { x: 684, y: -36976, yaw: 30 },
    { x: -3242, y: -34709, yaw: 90 },
    { x: -4551, y: -36974, yaw: 90 },
    { x: -1934, y: -36976, yaw: 30 },
    { x: 686, y: -27907, yaw: 30 },
    { x: -623, y: -30174, yaw: 30 },
    { x: 685, y: -32443, yaw: 30 },
    { x: -1933, y: -32442, yaw: 90 },
    { x: 1995, y: -30175, yaw: 30 },
    { x: 1994, y: -34711, yaw: 30 },
    { x: -1931, y: -27906, yaw: 30 },
    { x: -4550, y: -27903, yaw: 90 },
    { x: -5858, y: -30172, yaw: 30 },
    { x: -3240, y: -30173, yaw: 90 },
    { x: -7201, y: -2561, yaw: -60, half: true },
    { x: -8511, y: -4829, yaw: 0, half: true },
    { x: -8510, y: -9366, yaw: 0, half: true },
    { x: -7202, y: -11634, yaw: 60, half: true },
    { x: -3289, y: -13893, yaw: 60, half: true },
    { x: -658, y: -13901, yaw: 120, half: true },
    { x: 3247, y: -11651, yaw: 120, half: true },
    { x: 4580, y: -9371, yaw: 180, half: true },
    { x: -655, y: -299, yaw: 240, half: true },
    { x: -3272, y: -297, yaw: -60, half: true },
    { x: 4546, y: -4834, yaw: 180, half: true },
    { x: -657, y: -9368, yaw: 30 },
    { x: -7202, y: -7097, yaw: 30 },
    { x: -5892, y: -9366, yaw: 30 },
    { x: -4584, y: -7099, yaw: 30 },
    { x: 3268, y: -7101, yaw: 30 },
    { x: 3230, y: -2545, yaw: 240, half: true },
    { x: 651, y: -11635, yaw: 30 },
    { x: -3275, y: -9367, yaw: 90 },
    { x: -4584, y: -11632, yaw: 90 },
    { x: -1967, y: -11634, yaw: 30 },
    { x: 653, y: -2565, yaw: 30 },
    { x: -656, y: -4833, yaw: 30 },
    { x: 652, y: -7101, yaw: 30 },
    { x: -1966, y: -7100, yaw: 90 },
    { x: 1962, y: -4834, yaw: 30 },
    { x: 1961, y: -9369, yaw: 30 },
    { x: -1964, y: -2564, yaw: 30 },
    { x: -4583, y: -2561, yaw: 90 },
    { x: -5891, y: -4830, yaw: 30 },
    { x: -3273, y: -4831, yaw: 90 },
    { x: -14495, y: -15213, yaw: -60, half: true },
    { x: -15804, y: -17481, yaw: 0, half: true },
    { x: -15804, y: -22018, yaw: 0, half: true },
    { x: -14496, y: -24286, yaw: 60, half: true },
    { x: -10583, y: -26545, yaw: 60, half: true },
    { x: -7951, y: -26553, yaw: 120, half: true },
    { x: -4047, y: -24303, yaw: 120, half: true },
    { x: -2714, y: -22023, yaw: 180, half: true },
    { x: -7949, y: -12950, yaw: 240, half: true },
    { x: -10565, y: -12948, yaw: -60, half: true },
    { x: -2748, y: -17486, yaw: 180, half: true },
    { x: -7951, y: -22020, yaw: 30 },
    { x: -14496, y: -19749, yaw: 30 },
    { x: -13186, y: -22018, yaw: 30 },
    { x: -11877, y: -19751, yaw: 30 },
    { x: -4025, y: -19753, yaw: 30 },
    { x: -4063, y: -15196, yaw: 240, half: true },
    { x: -6643, y: -24286, yaw: 30 },
    { x: -10568, y: -22019, yaw: 90 },
    { x: -11878, y: -24284, yaw: 90 },
    { x: -9260, y: -24286, yaw: 30 },
    { x: -6641, y: -15217, yaw: 30 },
    { x: -7950, y: -17485, yaw: 30 },
    { x: -6642, y: -19753, yaw: 30 },
    { x: -9260, y: -19752, yaw: 90 },
    { x: -5332, y: -17486, yaw: 30 },
    { x: -5333, y: -22021, yaw: 30 },
    { x: -9257, y: -15216, yaw: 30 },
    { x: -11876, y: -15213, yaw: 90 },
    { x: -13184, y: -17482, yaw: 30 },
    { x: -10567, y: -17483, yaw: 90 },
    { x: 158, y: -40593, yaw: -60, half: true },
    { x: -1151, y: -42862, yaw: 0, half: true },
    { x: -1150, y: -47398, yaw: 0, half: true },
    { x: 157, y: -49666, yaw: 60, half: true },
    { x: 4071, y: -51925, yaw: 60, half: true },
    { x: 6702, y: -51934, yaw: 120, half: true },
    { x: 10606, y: -49683, yaw: 120, half: true },
    { x: 11940, y: -47404, yaw: 180, half: true },
    { x: 6705, y: -38331, yaw: 240, half: true },
    { x: 4088, y: -38329, yaw: -60, half: true },
    { x: 11905, y: -42866, yaw: 180, half: true },
    { x: 6702, y: -47401, yaw: 30 },
    { x: 157, y: -45130, yaw: 30 },
    { x: 1468, y: -47398, yaw: 30 },
    { x: 2776, y: -45131, yaw: 30 },
    { x: 10628, y: -45134, yaw: 30 },
    { x: 10590, y: -40577, yaw: 240, half: true },
    { x: 8010, y: -49667, yaw: 30 },
    { x: 4085, y: -47399, yaw: 90 },
    { x: 2775, y: -49665, yaw: 90 },
    { x: 5393, y: -49666, yaw: 30 },
    { x: 8012, y: -40597, yaw: 30 },
    { x: 6704, y: -42865, yaw: 30 },
    { x: 8011, y: -45133, yaw: 30 },
    { x: 5394, y: -45132, yaw: 90 },
    { x: 9321, y: -42866, yaw: 30 },
    { x: 9321, y: -47401, yaw: 30 },
    { x: 5396, y: -40597, yaw: 30 },
    { x: 2777, y: -40593, yaw: 90 },
    { x: 1469, y: -42862, yaw: 30 },
    { x: 4086, y: -42864, yaw: 90 },
    { x: -29160, y: -15193, yaw: -60, half: true },
    { x: -30470, y: -17461, yaw: 0, half: true },
    { x: -30469, y: -21998, yaw: 0, half: true },
    { x: -29161, y: -24266, yaw: 60, half: true },
    { x: -25248, y: -26525, yaw: 60, half: true },
    { x: -22617, y: -26533, yaw: 120, half: true },
    { x: -18712, y: -24282, yaw: 120, half: true },
    { x: -17379, y: -22003, yaw: 180, half: true },
    { x: -22614, y: -12930, yaw: 240, half: true },
    { x: -25230, y: -12928, yaw: -60, half: true },
    { x: -17413, y: -17465, yaw: 180, half: true },
    { x: -22616, y: -22000, yaw: 30 },
    { x: -29161, y: -19729, yaw: 30 },
    { x: -27851, y: -21998, yaw: 30 },
    { x: -26542, y: -19731, yaw: 30 },
    { x: -18691, y: -19733, yaw: 30 },
    { x: -18728, y: -15176, yaw: 240, half: true },
    { x: -21308, y: -24266, yaw: 30 },
    { x: -25233, y: -21999, yaw: 90 },
    { x: -26543, y: -24264, yaw: 90 },
    { x: -23925, y: -24266, yaw: 30 },
    { x: -21306, y: -15197, yaw: 30 },
    { x: -22615, y: -17464, yaw: 30 },
    { x: -21307, y: -19733, yaw: 30 },
    { x: -23925, y: -19732, yaw: 90 },
    { x: -19997, y: -17465, yaw: 30 },
    { x: -19998, y: -22001, yaw: 30 },
    { x: -23923, y: -15196, yaw: 30 },
    { x: -26542, y: -15193, yaw: 90 },
    { x: -27850, y: -17462, yaw: 30 },
    { x: -25232, y: -17463, yaw: 90 },
    { x: -32046, y: -17508, yaw: 180, half: true },
    { x: -33356, y: -15239, yaw: 240, half: true },
    { x: -37285, y: -12972, yaw: 240, half: true },
    { x: -39903, y: -12970, yaw: -60, half: true },
    { x: -43816, y: -15230, yaw: -60, half: true },
    { x: -45139, y: -17504, yaw: 0, half: true },
    { x: -45142, y: -22011, yaw: 0, half: true },
    { x: -43834, y: -24305, yaw: 60, half: true },
    { x: -33360, y: -24308, yaw: 120, half: true },
    { x: -32050, y: -22043, yaw: 180, half: true },
    { x: -39888, y: -26544, yaw: 60, half: true },
    { x: -41213, y: -19771, yaw: -90 },
    { x: -35974, y: -15239, yaw: -90 },
    { x: -38594, y: -15239, yaw: -90 },
    { x: -37285, y: -17506, yaw: -90 },
    { x: -41213, y: -24304, yaw: -90 },
    { x: -37247, y: -26550, yaw: 120, half: true },
    { x: -43830, y: -19771, yaw: -90 },
    { x: -39903, y: -17505, yaw: -30 },
    { x: -41210, y: -15238, yaw: -30 },
    { x: -42520, y: -17505, yaw: -90 },
    { x: -35976, y: -24308, yaw: -90 },
    { x: -37286, y: -22040, yaw: -90 },
    { x: -39904, y: -22039, yaw: -90 },
    { x: -38595, y: -19772, yaw: -30 },
    { x: -38596, y: -24307, yaw: -90 },
    { x: -42523, y: -22039, yaw: -90 },
    { x: -34668, y: -22042, yaw: -90 },
    { x: -33355, y: -19775, yaw: -30 },
    { x: -34666, y: -17508, yaw: -90 },
    { x: -35976, y: -19774, yaw: -30 },
    { x: -47987, y: -24328, yaw: 120, half: true },
    { x: -46678, y: -22060, yaw: 180, half: true },
    { x: -46679, y: -17523, yaw: 180, half: true },
    { x: -47986, y: -15255, yaw: 240, half: true },
    { x: -51899, y: -12996, yaw: 240, half: true },
    { x: -54531, y: -12988, yaw: -60, half: true },
    { x: -58435, y: -15238, yaw: -60, half: true },
    { x: -59769, y: -17518, yaw: 0, half: true },
    { x: -54534, y: -26591, yaw: 60, half: true },
    { x: -51917, y: -26593, yaw: 120, half: true },
    { x: -59734, y: -22055, yaw: 0, half: true },
    { x: -54531, y: -17521, yaw: 210 },
    { x: -47986, y: -19792, yaw: 210 },
    { x: -49296, y: -17523, yaw: 210 },
    { x: -50605, y: -19790, yaw: 210 },
    { x: -58457, y: -19788, yaw: 210 },
    { x: -58419, y: -24345, yaw: 60, half: true },
    { x: -55839, y: -15255, yaw: 210 },
    { x: -51914, y: -17522, yaw: -90 },
    { x: -50604, y: -15257, yaw: -90 },
    { x: -53222, y: -15255, yaw: 210 },
    { x: -55841, y: -24324, yaw: 210 },
    { x: -54533, y: -22057, yaw: 210 },
    { x: -55840, y: -19788, yaw: 210 },
    { x: -53223, y: -19789, yaw: -90 },
    { x: -57150, y: -22056, yaw: 210 },
    { x: -57150, y: -17520, yaw: 210 },
    { x: -53225, y: -24325, yaw: 210 },
    { x: -50606, y: -24328, yaw: -90 },
    { x: -49298, y: -22059, yaw: 210 },
    { x: -51915, y: -22058, yaw: -90 },
    { x: -44608, y: -279, yaw: 240, half: true },
    { x: -47227, y: -279, yaw: -60, half: true },
    { x: -51156, y: -2548, yaw: -60, half: true },
    { x: -52466, y: -4814, yaw: 0, half: true },
    { x: -52466, y: -9333, yaw: 0, half: true },
    { x: -51157, y: -11616, yaw: 60, half: true },
    { x: -47256, y: -13872, yaw: 60, half: true },
    { x: -44615, y: -13887, yaw: 120, half: true },
    { x: -39375, y: -4817, yaw: 180, half: true },
    { x: -40682, y: -2549, yaw: 240, half: true },
    { x: -40702, y: -11588, yaw: 120, half: true },
    { x: -47231, y: -9350, yaw: -30 },
    { x: -48537, y: -2546, yaw: -30 },
    { x: -49846, y: -4815, yaw: -30 },
    { x: -47229, y: -4815, yaw: -30 },
    { x: -43305, y: -11616, yaw: -30 },
    { x: -39378, y: -9304, yaw: 180, half: true },
    { x: -48540, y: -11615, yaw: -30 },
    { x: -48539, y: -7082, yaw: 30 },
    { x: -51155, y: -7081, yaw: 30 },
    { x: -49848, y: -9348, yaw: -30 },
    { x: -40684, y: -7082, yaw: -30 },
    { x: -43302, y: -7083, yaw: -30 },
    { x: -44613, y: -9350, yaw: -30 },
    { x: -45921, y: -7082, yaw: 30 },
    { x: -41994, y: -9350, yaw: -30 },
    { x: -45922, y: -11617, yaw: -30 },
    { x: -41992, y: -4816, yaw: -30 },
    { x: -43299, y: -2546, yaw: 30 },
    { x: -45918, y: -2548, yaw: -30 },
    { x: -44610, y: -4816, yaw: 30 },
    { x: -65787, y: -2554, yaw: -60, half: true },
    { x: -67097, y: -4823, yaw: 0, half: true },
    { x: -67096, y: -9360, yaw: 0, half: true },
    { x: -65789, y: -11627, yaw: 60, half: true },
    { x: -61875, y: -13886, yaw: 60, half: true },
    { x: -59244, y: -13895, yaw: 120, half: true },
    { x: -55340, y: -11644, yaw: 120, half: true },
    { x: -54006, y: -9365, yaw: 180, half: true },
    { x: -59241, y: -292, yaw: 240, half: true },
    { x: -61858, y: -290, yaw: -60, half: true },
    { x: -54041, y: -4827, yaw: 180, half: true },
    { x: -59244, y: -9362, yaw: 30 },
    { x: -65789, y: -7091, yaw: 30 },
    { x: -64478, y: -9359, yaw: 30 },
    { x: -63170, y: -7092, yaw: 30 },
    { x: -55318, y: -7095, yaw: 30 },
    { x: -55356, y: -2538, yaw: 240, half: true },
    { x: -57936, y: -11628, yaw: 30 },
    { x: -61861, y: -9360, yaw: 90 },
    { x: -63171, y: -11626, yaw: 90 },
    { x: -60553, y: -11627, yaw: 30 },
    { x: -57934, y: -2558, yaw: 30 },
    { x: -59242, y: -4826, yaw: 30 },
    { x: -57935, y: -7094, yaw: 30 },
    { x: -60552, y: -7093, yaw: 90 },
    { x: -56625, y: -4827, yaw: 30 },
    { x: -56625, y: -9362, yaw: 30 },
    { x: -60550, y: -2558, yaw: 30 },
    { x: -63169, y: -2554, yaw: 90 },
    { x: -64477, y: -4823, yaw: 30 },
    { x: -61860, y: -4825, yaw: 90 },
    { x: -40667, y: -37007, yaw: 120, half: true },
    { x: -39358, y: -34739, yaw: 180, half: true },
    { x: -39358, y: -30202, yaw: 180, half: true },
    { x: -40666, y: -27934, yaw: 240, half: true },
    { x: -44579, y: -25675, yaw: 240, half: true },
    { x: -47211, y: -25667, yaw: -60, half: true },
    { x: -51115, y: -27917, yaw: -60, half: true },
    { x: -52448, y: -30197, yaw: 0, half: true },
    { x: -47213, y: -39270, yaw: 60, half: true },
    { x: -44597, y: -39272, yaw: 120, half: true },
    { x: -52414, y: -34734, yaw: 0, half: true },
    { x: -47211, y: -30200, yaw: 210 },
    { x: -40666, y: -32471, yaw: 210 },
    { x: -41976, y: -30202, yaw: 210 },
    { x: -43285, y: -32469, yaw: 210 },
    { x: -51137, y: -32467, yaw: 210 },
    { x: -51099, y: -37024, yaw: 60, half: true },
    { x: -48519, y: -27933, yaw: 210 },
    { x: -44594, y: -30201, yaw: -90 },
    { x: -43284, y: -27936, yaw: -90 },
    { x: -45902, y: -27934, yaw: 210 },
    { x: -48521, y: -37003, yaw: 210 },
    { x: -47213, y: -34735, yaw: 210 },
    { x: -48520, y: -32467, yaw: 210 },
    { x: -45902, y: -32468, yaw: -90 },
    { x: -49830, y: -34734, yaw: 210 },
    { x: -49830, y: -30199, yaw: 210 },
    { x: -45905, y: -37004, yaw: 210 },
    { x: -43286, y: -37007, yaw: -90 },
    { x: -41978, y: -34738, yaw: 210 },
    { x: -44595, y: -34737, yaw: -90 },
    { x: -61907, y: -39277, yaw: 60, half: true },
    { x: -59288, y: -39277, yaw: 120, half: true },
    { x: -55360, y: -37008, yaw: 120, half: true },
    { x: -54050, y: -34741, yaw: 180, half: true },
    { x: -54050, y: -30223, yaw: 180, half: true },
    { x: -55358, y: -27940, yaw: 240, half: true },
    { x: -59260, y: -25684, yaw: 240, half: true },
    { x: -61900, y: -25669, yaw: -60, half: true },
    { x: -67140, y: -34739, yaw: 0, half: true },
    { x: -65833, y: -37006, yaw: 60, half: true },
    { x: -65813, y: -27967, yaw: -60, half: true },
    { x: -59284, y: -30206, yaw: 150 },
    { x: -57978, y: -37010, yaw: 150 },
    { x: -56669, y: -34741, yaw: 150 },
    { x: -59286, y: -34741, yaw: 150 },
    { x: -63210, y: -27940, yaw: 150 },
    { x: -67138, y: -30251, yaw: 0, half: true },
    { x: -57976, y: -27940, yaw: 150 },
    { x: -57977, y: -32473, yaw: 210 },
    { x: -55360, y: -32475, yaw: 210 },
    { x: -56668, y: -30207, yaw: 150 },
    { x: -65831, y: -32473, yaw: 150 },
    { x: -63213, y: -32473, yaw: 150 },
    { x: -61902, y: -30206, yaw: 150 },
    { x: -60594, y: -32473, yaw: 210 },
    { x: -64521, y: -30205, yaw: 150 },
    { x: -60593, y: -27938, yaw: 150 },
    { x: -64523, y: -34739, yaw: 150 },
    { x: -63217, y: -37009, yaw: 210 },
    { x: -60598, y: -37007, yaw: 150 },
    { x: -61905, y: -34740, yaw: 210 },
    { x: 36754, y: 22781, yaw: -60, half: true },
    { x: 35444, y: 20513, yaw: 0, half: true },
    { x: 35445, y: 15976, yaw: 0, half: true },
    { x: 36753, y: 13709, yaw: 60, half: true },
    { x: 40666, y: 11449, yaw: 60, half: true },
    { x: 43297, y: 11441, yaw: 120, half: true },
    { x: 47201, y: 13692, yaw: 120, half: true },
    { x: 48535, y: 15971, yaw: 180, half: true },
    { x: 43300, y: 25044, yaw: 240, half: true },
    { x: 40683, y: 25046, yaw: -60, half: true },
    { x: 48500, y: 20509, yaw: 180, half: true },
    { x: 43297, y: 15974, yaw: 30 },
    { x: 36752, y: 18245, yaw: 30 },
    { x: 38063, y: 15977, yaw: 30 },
    { x: 39371, y: 18244, yaw: 30 },
    { x: 47223, y: 18241, yaw: 30 },
    { x: 47185, y: 22798, yaw: 240, half: true },
    { x: 44605, y: 13708, yaw: 30 },
    { x: 40680, y: 15975, yaw: 90 },
    { x: 39370, y: 13710, yaw: 90 },
    { x: 41988, y: 13709, yaw: 30 },
    { x: 44608, y: 22778, yaw: 30 },
    { x: 43299, y: 20510, yaw: 30 },
    { x: 44607, y: 18242, yaw: 30 },
    { x: 41989, y: 18242, yaw: 90 },
    { x: 45917, y: 20509, yaw: 30 },
    { x: 45916, y: 15974, yaw: 30 },
    { x: 41991, y: 22778, yaw: 30 },
    { x: 39372, y: 22781, yaw: 90 },
    { x: 38064, y: 20512, yaw: 30 },
    { x: 40682, y: 20511, yaw: 90 },
    { x: 44078, y: 35474, yaw: -60, half: true },
    { x: 42769, y: 33206, yaw: 0, half: true },
    { x: 42770, y: 28669, yaw: 0, half: true },
    { x: 44077, y: 26402, yaw: 60, half: true },
    { x: 47990, y: 24142, yaw: 60, half: true },
    { x: 50622, y: 24134, yaw: 120, half: true },
    { x: 54526, y: 26385, yaw: 120, half: true },
    { x: 55860, y: 28664, yaw: 180, half: true },
    { x: 50625, y: 37737, yaw: 240, half: true },
    { x: 48008, y: 37739, yaw: -60, half: true },
    { x: 55825, y: 33202, yaw: 180, half: true },
    { x: 50622, y: 28667, yaw: 30 },
    { x: 44077, y: 30938, yaw: 30 },
    { x: 45387, y: 28670, yaw: 30 },
    { x: 46696, y: 30937, yaw: 30 },
    { x: 54548, y: 30934, yaw: 30 },
    { x: 54510, y: 35491, yaw: 240, half: true },
    { x: 51930, y: 26401, yaw: 30 },
    { x: 48005, y: 28668, yaw: 90 },
    { x: 46695, y: 26403, yaw: 90 },
    { x: 49313, y: 26402, yaw: 30 },
    { x: 51932, y: 35471, yaw: 30 },
    { x: 50624, y: 33203, yaw: 30 },
    { x: 51931, y: 30935, yaw: 30 },
    { x: 49314, y: 30935, yaw: 90 },
    { x: 53241, y: 33202, yaw: 30 },
    { x: 53241, y: 28667, yaw: 30 },
    { x: 49316, y: 35471, yaw: 30 },
    { x: 46697, y: 35474, yaw: 90 },
    { x: 45389, y: 33205, yaw: 30 },
    { x: 48006, y: 33204, yaw: 90 },
    { x: 51396, y: 48140, yaw: -60, half: true },
    { x: 50086, y: 45872, yaw: 0, half: true },
    { x: 50087, y: 41335, yaw: 0, half: true },
    { x: 51395, y: 39067, yaw: 60, half: true },
    { x: 55308, y: 36808, yaw: 60, half: true },
    { x: 57939, y: 36800, yaw: 120, half: true },
    { x: 61844, y: 39051, yaw: 120, half: true },
    { x: 63177, y: 41330, yaw: 180, half: true },
    { x: 57942, y: 50403, yaw: 240, half: true },
    { x: 55326, y: 50405, yaw: -60, half: true },
    { x: 63143, y: 45868, yaw: 180, half: true },
    { x: 57940, y: 41333, yaw: 30 },
    { x: 51395, y: 43604, yaw: 30 },
    { x: 52705, y: 41335, yaw: 30 },
    { x: 54014, y: 43602, yaw: 30 },
    { x: 61865, y: 43600, yaw: 30 },
    { x: 61828, y: 48157, yaw: 240, half: true },
    { x: 59248, y: 39067, yaw: 30 },
    { x: 55323, y: 41334, yaw: 90 },
    { x: 54013, y: 39069, yaw: 90 },
    { x: 56631, y: 39067, yaw: 30 },
    { x: 59250, y: 48136, yaw: 30 },
    { x: 57941, y: 45869, yaw: 30 },
    { x: 59249, y: 43600, yaw: 30 },
    { x: 56631, y: 43601, yaw: 90 },
    { x: 60559, y: 45868, yaw: 30 },
    { x: 60558, y: 41332, yaw: 30 },
    { x: 56633, y: 48137, yaw: 30 },
    { x: 54014, y: 48140, yaw: 90 },
    { x: 52706, y: 45871, yaw: 30 },
    { x: 55324, y: 45870, yaw: 90 },
    { x: 58706, y: 35479, yaw: -60, half: true },
    { x: 57396, y: 33211, yaw: 0, half: true },
    { x: 57397, y: 28674, yaw: 0, half: true },
    { x: 58705, y: 26406, yaw: 60, half: true },
    { x: 62618, y: 24147, yaw: 60, half: true },
    { x: 65249, y: 24139, yaw: 120, half: true },
    { x: 69154, y: 26389, yaw: 120, half: true },
    { x: 70487, y: 28669, yaw: 180, half: true },
    { x: 65252, y: 37741, yaw: 240, half: true },
    { x: 62636, y: 37743, yaw: -60, half: true },
    { x: 70453, y: 33206, yaw: 180, half: true },
    { x: 65250, y: 28672, yaw: 30 },
    { x: 58705, y: 30943, yaw: 30 },
    { x: 60015, y: 28674, yaw: 30 },
    { x: 61324, y: 30941, yaw: 30 },
    { x: 69175, y: 30939, yaw: 30 },
    { x: 69138, y: 35495, yaw: 240, half: true },
    { x: 66558, y: 26405, yaw: 30 },
    { x: 62633, y: 28673, yaw: 90 },
    { x: 61323, y: 26408, yaw: 90 },
    { x: 63941, y: 26406, yaw: 30 },
    { x: 66560, y: 35475, yaw: 30 },
    { x: 65251, y: 33207, yaw: 30 },
    { x: 66559, y: 30939, yaw: 30 },
    { x: 63941, y: 30940, yaw: 90 },
    { x: 67869, y: 33206, yaw: 30 },
    { x: 67868, y: 28671, yaw: 30 },
    { x: 63943, y: 35476, yaw: 30 },
    { x: 61324, y: 35479, yaw: 90 },
    { x: 60016, y: 33210, yaw: 30 },
    { x: 62634, y: 33209, yaw: 90 },
    { x: 65993, y: 48138, yaw: -60, half: true },
    { x: 64683, y: 45870, yaw: 0, half: true },
    { x: 64684, y: 41333, yaw: 0, half: true },
    { x: 65991, y: 39065, yaw: 60, half: true },
    { x: 69905, y: 36806, yaw: 60, half: true },
    { x: 72536, y: 36798, yaw: 120, half: true },
    { x: 76440, y: 39049, yaw: 120, half: true },
    { x: 77774, y: 41328, yaw: 180, half: true },
    { x: 72539, y: 50401, yaw: 240, half: true },
    { x: 69922, y: 50403, yaw: -60, half: true },
    { x: 77739, y: 45866, yaw: 180, half: true },
    { x: 72536, y: 41331, yaw: 30 },
    { x: 65991, y: 43602, yaw: 30 },
    { x: 67302, y: 41333, yaw: 30 },
    { x: 68610, y: 43600, yaw: 30 },
    { x: 76462, y: 43598, yaw: 30 },
    { x: 76424, y: 48155, yaw: 240, half: true },
    { x: 73844, y: 39065, yaw: 30 },
    { x: 69919, y: 41332, yaw: 90 },
    { x: 68609, y: 39067, yaw: 90 },
    { x: 71227, y: 39065, yaw: 30 },
    { x: 73846, y: 48134, yaw: 30 },
    { x: 72538, y: 45867, yaw: 30 },
    { x: 73845, y: 43598, yaw: 30 },
    { x: 71228, y: 43599, yaw: 90 },
    { x: 75155, y: 45866, yaw: 30 },
    { x: 75155, y: 41330, yaw: 30 },
    { x: 71230, y: 48135, yaw: 30 },
    { x: 68611, y: 48138, yaw: 90 },
    { x: 67303, y: 45869, yaw: 30 },
    { x: 69921, y: 45868, yaw: 90 },
    { x: 36748, y: 48170, yaw: -60, half: true },
    { x: 35439, y: 45902, yaw: 0, half: true },
    { x: 35440, y: 41365, yaw: 0, half: true },
    { x: 36747, y: 39098, yaw: 60, half: true },
    { x: 40660, y: 36838, yaw: 60, half: true },
    { x: 43292, y: 36830, yaw: 120, half: true },
    { x: 47196, y: 39081, yaw: 120, half: true },
    { x: 48530, y: 41360, yaw: 180, half: true },
    { x: 43295, y: 50433, yaw: 240, half: true },
    { x: 40678, y: 50435, yaw: -60, half: true },
    { x: 48495, y: 45898, yaw: 180, half: true },
    { x: 43292, y: 41363, yaw: 30 },
    { x: 36747, y: 43634, yaw: 30 },
    { x: 38057, y: 41366, yaw: 30 },
    { x: 39366, y: 43633, yaw: 30 },
    { x: 47218, y: 43630, yaw: 30 },
    { x: 47180, y: 48187, yaw: 240, half: true },
    { x: 44600, y: 39097, yaw: 30 },
    { x: 40675, y: 41364, yaw: 90 },
    { x: 39365, y: 39099, yaw: 90 },
    { x: 41983, y: 39098, yaw: 30 },
    { x: 44602, y: 48167, yaw: 30 },
    { x: 43294, y: 45899, yaw: 30 },
    { x: 44601, y: 43630, yaw: 30 },
    { x: 41984, y: 43631, yaw: 90 },
    { x: 45911, y: 45898, yaw: 30 },
    { x: 45911, y: 41363, yaw: 30 },
    { x: 41986, y: 48167, yaw: 30 },
    { x: 39367, y: 48170, yaw: 90 },
    { x: 38059, y: 45901, yaw: 30 },
    { x: 40676, y: 45900, yaw: 90 },
    { x: 44086, y: 60801, yaw: -60, half: true },
    { x: 42776, y: 58533, yaw: 0, half: true },
    { x: 42777, y: 53996, yaw: 0, half: true },
    { x: 44085, y: 51729, yaw: 60, half: true },
    { x: 47998, y: 49469, yaw: 60, half: true },
    { x: 50629, y: 49461, yaw: 120, half: true },
    { x: 54534, y: 51712, yaw: 120, half: true },
    { x: 55867, y: 53991, yaw: 180, half: true },
    { x: 50632, y: 63064, yaw: 240, half: true },
    { x: 48016, y: 63066, yaw: -60, half: true },
    { x: 55833, y: 58529, yaw: 180, half: true },
    { x: 50630, y: 53994, yaw: 30 },
    { x: 44085, y: 56265, yaw: 30 },
    { x: 45395, y: 53997, yaw: 30 },
    { x: 46704, y: 56264, yaw: 30 },
    { x: 54555, y: 56261, yaw: 30 },
    { x: 54518, y: 60818, yaw: 240, half: true },
    { x: 51938, y: 51728, yaw: 30 },
    { x: 48013, y: 53995, yaw: 90 },
    { x: 46703, y: 51730, yaw: 90 },
    { x: 49321, y: 51729, yaw: 30 },
    { x: 51940, y: 60798, yaw: 30 },
    { x: 50631, y: 58530, yaw: 30 },
    { x: 51939, y: 56262, yaw: 30 },
    { x: 49321, y: 56262, yaw: 90 },
    { x: 53249, y: 58529, yaw: 30 },
    { x: 53248, y: 53994, yaw: 30 },
    { x: 49323, y: 60798, yaw: 30 },
    { x: 46704, y: 60801, yaw: 90 },
    { x: 45396, y: 58532, yaw: 30 },
    { x: 48014, y: 58531, yaw: 90 },
    { x: 36792, y: -53308, yaw: -60, half: true },
    { x: 35483, y: -55576, yaw: 0, half: true },
    { x: 35483, y: -60113, yaw: 0, half: true },
    { x: 36791, y: -62380, yaw: 60, half: true },
    { x: 40704, y: -64640, yaw: 60, half: true },
    { x: 43336, y: -64648, yaw: 120, half: true },
    { x: 47240, y: -62397, yaw: 120, half: true },
    { x: 48573, y: -60118, yaw: 180, half: true },
    { x: 43338, y: -51045, yaw: 240, half: true },
    { x: 40722, y: -51043, yaw: -60, half: true },
    { x: 48539, y: -55580, yaw: 180, half: true },
    { x: 43336, y: -60115, yaw: 30 },
    { x: 36791, y: -57844, yaw: 30 },
    { x: 38101, y: -60112, yaw: 30 },
    { x: 39410, y: -57845, yaw: 30 },
    { x: 47262, y: -57848, yaw: 30 },
    { x: 47224, y: -53291, yaw: 240, half: true },
    { x: 44644, y: -62381, yaw: 30 },
    { x: 40719, y: -60114, yaw: 90 },
    { x: 39409, y: -62379, yaw: 90 },
    { x: 42027, y: -62380, yaw: 30 },
    { x: 44646, y: -53311, yaw: 30 },
    { x: 43337, y: -55579, yaw: 30 },
    { x: 44645, y: -57847, yaw: 30 },
    { x: 42027, y: -57847, yaw: 90 },
    { x: 45955, y: -55580, yaw: 30 },
    { x: 45955, y: -60115, yaw: 30 },
    { x: 42030, y: -53311, yaw: 30 },
    { x: 39411, y: -53308, yaw: 90 },
    { x: 38103, y: -55577, yaw: 30 },
    { x: 40720, y: -55578, yaw: 90 },
    { x: 44107, y: -65978, yaw: -60, half: true },
    { x: 42798, y: -68246, yaw: 0, half: true },
    { x: 42798, y: -72783, yaw: 0, half: true },
    { x: 44106, y: -75050, yaw: 60, half: true },
    { x: 48019, y: -77310, yaw: 60, half: true },
    { x: 50651, y: -77318, yaw: 120, half: true },
    { x: 54555, y: -75067, yaw: 120, half: true },
    { x: 55888, y: -72788, yaw: 180, half: true },
    { x: 50653, y: -63715, yaw: 240, half: true },
    { x: 48037, y: -63713, yaw: -60, half: true },
    { x: 55854, y: -68250, yaw: 180, half: true },
    { x: 50651, y: -72785, yaw: 30 },
    { x: 44106, y: -70514, yaw: 30 },
    { x: 45416, y: -72782, yaw: 30 },
    { x: 46725, y: -70515, yaw: 30 },
    { x: 54577, y: -70518, yaw: 30 },
    { x: 54539, y: -65961, yaw: 240, half: true },
    { x: 51959, y: -75051, yaw: 30 },
    { x: 48034, y: -72784, yaw: 90 },
    { x: 46724, y: -75049, yaw: 90 },
    { x: 49342, y: -75050, yaw: 30 },
    { x: 51961, y: -65981, yaw: 30 },
    { x: 50652, y: -68249, yaw: 30 },
    { x: 51960, y: -70517, yaw: 30 },
    { x: 49342, y: -70517, yaw: 90 },
    { x: 53270, y: -68250, yaw: 30 },
    { x: 53270, y: -72785, yaw: 30 },
    { x: 49345, y: -65981, yaw: 30 },
    { x: 46726, y: -65978, yaw: 90 },
    { x: 45418, y: -68247, yaw: 30 },
    { x: 48035, y: -68248, yaw: 90 },
    { x: 51447, y: -78691, yaw: -60, half: true },
    { x: 50138, y: -80959, yaw: 0, half: true },
    { x: 50138, y: -85496, yaw: 0, half: true },
    { x: 51446, y: -87764, yaw: 60, half: true },
    { x: 55359, y: -90023, yaw: 60, half: true },
    { x: 57991, y: -90031, yaw: 120, half: true },
    { x: 61895, y: -87780, yaw: 120, half: true },
    { x: 63228, y: -85501, yaw: 180, half: true },
    { x: 57993, y: -76428, yaw: 240, half: true },
    { x: 55377, y: -76426, yaw: -60, half: true },
    { x: 63194, y: -80963, yaw: 180, half: true },
    { x: 57991, y: -85498, yaw: 30 },
    { x: 51446, y: -83227, yaw: 30 },
    { x: 52756, y: -85496, yaw: 30 },
    { x: 54065, y: -83229, yaw: 30 },
    { x: 61917, y: -83231, yaw: 30 },
    { x: 61879, y: -78674, yaw: 240, half: true },
    { x: 59299, y: -87764, yaw: 30 },
    { x: 55374, y: -85497, yaw: 90 },
    { x: 54064, y: -87762, yaw: 90 },
    { x: 56682, y: -87763, yaw: 30 },
    { x: 59301, y: -78694, yaw: 30 },
    { x: 57992, y: -80962, yaw: 30 },
    { x: 59300, y: -83231, yaw: 30 },
    { x: 56682, y: -83230, yaw: 90 },
    { x: 60610, y: -80963, yaw: 30 },
    { x: 60610, y: -85499, yaw: 30 },
    { x: 56685, y: -78694, yaw: 30 },
    { x: 54066, y: -78691, yaw: 90 },
    { x: 52758, y: -80960, yaw: 30 },
    { x: 55375, y: -80961, yaw: 90 },
    { x: 36753, y: -78692, yaw: -60, half: true },
    { x: 35443, y: -80960, yaw: 0, half: true },
    { x: 35444, y: -85497, yaw: 0, half: true },
    { x: 36752, y: -87764, yaw: 60, half: true },
    { x: 40665, y: -90024, yaw: 60, half: true },
    { x: 43296, y: -90032, yaw: 120, half: true },
    { x: 47200, y: -87781, yaw: 120, half: true },
    { x: 48534, y: -85502, yaw: 180, half: true },
    { x: 43299, y: -76429, yaw: 240, half: true },
    { x: 40682, y: -76427, yaw: -60, half: true },
    { x: 48499, y: -80964, yaw: 180, half: true },
    { x: 43297, y: -85499, yaw: 30 },
    { x: 36752, y: -83228, yaw: 30 },
    { x: 38062, y: -85496, yaw: 30 },
    { x: 39370, y: -83229, yaw: 30 },
    { x: 47222, y: -83232, yaw: 30 },
    { x: 47184, y: -78675, yaw: 240, half: true },
    { x: 44604, y: -87765, yaw: 30 },
    { x: 40679, y: -85497, yaw: 90 },
    { x: 39369, y: -87763, yaw: 90 },
    { x: 41987, y: -87764, yaw: 30 },
    { x: 44607, y: -78695, yaw: 30 },
    { x: 43298, y: -80963, yaw: 30 },
    { x: 44606, y: -83231, yaw: 30 },
    { x: 41988, y: -83231, yaw: 90 },
    { x: 45916, y: -80964, yaw: 30 },
    { x: 45915, y: -85499, yaw: 30 },
    { x: 41990, y: -78695, yaw: 30 },
    { x: 39371, y: -78692, yaw: 90 },
    { x: 38063, y: -80961, yaw: 30 },
    { x: 40681, y: -80962, yaw: 90 },
    { x: 44093, y: -91405, yaw: -60, half: true },
    { x: 42783, y: -93673, yaw: 0, half: true },
    { x: 42784, y: -98210, yaw: 0, half: true },
    { x: 44092, y: -100478, yaw: 60, half: true },
    { x: 48005, y: -102737, yaw: 60, half: true },
    { x: 50636, y: -102745, yaw: 120, half: true },
    { x: 54540, y: -100494, yaw: 120, half: true },
    { x: 55874, y: -98215, yaw: 180, half: true },
    { x: 50639, y: -89142, yaw: 240, half: true },
    { x: 48022, y: -89140, yaw: -60, half: true },
    { x: 55839, y: -93677, yaw: 180, half: true },
    { x: 50637, y: -98212, yaw: 30 },
    { x: 44092, y: -95941, yaw: 30 },
    { x: 45402, y: -98210, yaw: 30 },
    { x: 46710, y: -95943, yaw: 30 },
    { x: 54562, y: -95945, yaw: 30 },
    { x: 54524, y: -91388, yaw: 240, half: true },
    { x: 51944, y: -100478, yaw: 30 },
    { x: 48019, y: -98211, yaw: 90 },
    { x: 46709, y: -100476, yaw: 90 },
    { x: 49327, y: -100477, yaw: 30 },
    { x: 51947, y: -91408, yaw: 30 },
    { x: 50638, y: -93676, yaw: 30 },
    { x: 51946, y: -95945, yaw: 30 },
    { x: 49328, y: -95944, yaw: 90 },
    { x: 53256, y: -93677, yaw: 30 },
    { x: 53255, y: -98212, yaw: 30 },
    { x: 49330, y: -91408, yaw: 30 },
    { x: 46711, y: -91405, yaw: 90 },
    { x: 45403, y: -93674, yaw: 30 },
    { x: 48021, y: -93675, yaw: 90 },
    { x: 58776, y: -65977, yaw: -60, half: true },
    { x: 57466, y: -68245, yaw: 0, half: true },
    { x: 57467, y: -72782, yaw: 0, half: true },
    { x: 58775, y: -75049, yaw: 60, half: true },
    { x: 62688, y: -77309, yaw: 60, half: true },
    { x: 65319, y: -77317, yaw: 120, half: true },
    { x: 69223, y: -75066, yaw: 120, half: true },
    { x: 70557, y: -72787, yaw: 180, half: true },
    { x: 65322, y: -63714, yaw: 240, half: true },
    { x: 62705, y: -63712, yaw: -60, half: true },
    { x: 70522, y: -68249, yaw: 180, half: true },
    { x: 65320, y: -72784, yaw: 30 },
    { x: 58775, y: -70513, yaw: 30 },
    { x: 60085, y: -72781, yaw: 30 },
    { x: 61393, y: -70514, yaw: 30 },
    { x: 69245, y: -70517, yaw: 30 },
    { x: 69207, y: -65960, yaw: 240, half: true },
    { x: 66627, y: -75050, yaw: 30 },
    { x: 62702, y: -72782, yaw: 90 },
    { x: 61393, y: -75048, yaw: 90 },
    { x: 64010, y: -75049, yaw: 30 },
    { x: 66630, y: -65980, yaw: 30 },
    { x: 65321, y: -68248, yaw: 30 },
    { x: 66629, y: -70516, yaw: 30 },
    { x: 64011, y: -70516, yaw: 90 },
    { x: 67939, y: -68249, yaw: 30 },
    { x: 67938, y: -72784, yaw: 30 },
    { x: 64013, y: -65980, yaw: 30 },
    { x: 61394, y: -65977, yaw: 90 },
    { x: 60086, y: -68246, yaw: 30 },
    { x: 62704, y: -68247, yaw: 90 },
    { x: 66121, y: -78698, yaw: -60, half: true },
    { x: 64811, y: -80967, yaw: 0, half: true },
    { x: 64812, y: -85504, yaw: 0, half: true },
    { x: 66120, y: -87771, yaw: 60, half: true },
    { x: 70033, y: -90030, yaw: 60, half: true },
    { x: 72664, y: -90039, yaw: 120, half: true },
    { x: 76568, y: -87788, yaw: 120, half: true },
    { x: 77902, y: -85509, yaw: 180, half: true },
    { x: 72667, y: -76436, yaw: 240, half: true },
    { x: 70050, y: -76434, yaw: -60, half: true },
    { x: 77867, y: -80971, yaw: 180, half: true },
    { x: 72665, y: -85506, yaw: 30 },
    { x: 66120, y: -83235, yaw: 30 },
    { x: 67430, y: -85503, yaw: 30 },
    { x: 68738, y: -83236, yaw: 30 },
    { x: 76590, y: -83239, yaw: 30 },
    { x: 76552, y: -78682, yaw: 240, half: true },
    { x: 73972, y: -87772, yaw: 30 },
    { x: 70047, y: -85504, yaw: 90 },
    { x: 68738, y: -87770, yaw: 90 },
    { x: 71355, y: -87771, yaw: 30 },
    { x: 73975, y: -78702, yaw: 30 },
    { x: 72666, y: -80970, yaw: 30 },
    { x: 73974, y: -83238, yaw: 30 },
    { x: 71356, y: -83237, yaw: 90 },
    { x: 75284, y: -80971, yaw: 30 },
    { x: 75283, y: -85506, yaw: 30 },
    { x: 71358, y: -78702, yaw: 30 },
    { x: 68739, y: -78698, yaw: 90 },
    { x: 67431, y: -80967, yaw: 30 },
    { x: 70049, y: -80969, yaw: 90 },
    { x: -10583, y: -51953, yaw: 60, half: true },
    { x: -7964, y: -51953, yaw: 120, half: true },
    { x: -4035, y: -49684, yaw: 120, half: true },
    { x: -2725, y: -47417, yaw: 180, half: true },
    { x: -2725, y: -42899, yaw: 180, half: true },
    { x: -4034, y: -40616, yaw: 240, half: true },
    { x: -7935, y: -38360, yaw: 240, half: true },
    { x: -10576, y: -38345, yaw: -60, half: true },
    { x: -15816, y: -47415, yaw: 0, half: true },
    { x: -14509, y: -49682, yaw: 60, half: true },
    { x: -14489, y: -40644, yaw: -60, half: true },
    { x: -7960, y: -42882, yaw: 150 },
    { x: -6654, y: -49686, yaw: 150 },
    { x: -5345, y: -47417, yaw: 150 },
    { x: -7962, y: -47417, yaw: 150 },
    { x: -11886, y: -40616, yaw: 150 },
    { x: -15813, y: -42927, yaw: 0, half: true },
    { x: -6651, y: -40616, yaw: 150 },
    { x: -6652, y: -45149, yaw: 210 },
    { x: -4036, y: -45151, yaw: 210 },
    { x: -5343, y: -42883, yaw: 150 },
    { x: -14507, y: -45149, yaw: 150 },
    { x: -11889, y: -45149, yaw: 150 },
    { x: -10578, y: -42882, yaw: 150 },
    { x: -9270, y: -45150, yaw: 210 },
    { x: -13197, y: -42881, yaw: 150 },
    { x: -9269, y: -40614, yaw: 150 },
    { x: -13199, y: -47416, yaw: 150 },
    { x: -11892, y: -49685, yaw: 210 },
    { x: -9273, y: -49684, yaw: 150 },
    { x: -10581, y: -47416, yaw: 210 },
    { x: -7161, y: -53291, yaw: -60, half: true },
    { x: -8470, y: -55559, yaw: 0, half: true },
    { x: -8470, y: -60096, yaw: 0, half: true },
    { x: -7162, y: -62364, yaw: 60, half: true },
    { x: -3249, y: -64623, yaw: 60, half: true },
    { x: -618, y: -64631, yaw: 120, half: true },
    { x: 3287, y: -62381, yaw: 120, half: true },
    { x: 4620, y: -60101, yaw: 180, half: true },
    { x: -615, y: -51028, yaw: 240, half: true },
    { x: -3231, y: -51026, yaw: -60, half: true },
    { x: 4586, y: -55564, yaw: 180, half: true },
    { x: -617, y: -60098, yaw: 30 },
    { x: -7162, y: -57827, yaw: 30 },
    { x: -5852, y: -60096, yaw: 30 },
    { x: -4543, y: -57829, yaw: 30 },
    { x: 3309, y: -57831, yaw: 30 },
    { x: 3271, y: -53274, yaw: 240, half: true },
    { x: 691, y: -62364, yaw: 30 },
    { x: -3234, y: -60097, yaw: 90 },
    { x: -4544, y: -62362, yaw: 90 },
    { x: -1926, y: -62364, yaw: 30 },
    { x: 693, y: -53295, yaw: 30 },
    { x: -616, y: -55562, yaw: 30 },
    { x: 692, y: -57831, yaw: 30 },
    { x: -1926, y: -57830, yaw: 90 },
    { x: 2002, y: -55563, yaw: 30 },
    { x: 2001, y: -60099, yaw: 30 },
    { x: -1924, y: -53294, yaw: 30 },
    { x: -4542, y: -53291, yaw: 90 },
    { x: -5850, y: -55560, yaw: 30 },
    { x: -3233, y: -55561, yaw: 90 },
    { x: 7487, y: -53321, yaw: -60, half: true },
    { x: 6177, y: -55590, yaw: 0, half: true },
    { x: 6178, y: -60126, yaw: 0, half: true },
    { x: 7486, y: -62394, yaw: 60, half: true },
    { x: 11399, y: -64653, yaw: 60, half: true },
    { x: 14030, y: -64662, yaw: 120, half: true },
    { x: 17934, y: -62411, yaw: 120, half: true },
    { x: 19268, y: -60131, yaw: 180, half: true },
    { x: 14033, y: -51059, yaw: 240, half: true },
    { x: 11416, y: -51057, yaw: -60, half: true },
    { x: 19233, y: -55594, yaw: 180, half: true },
    { x: 14030, y: -60128, yaw: 30 },
    { x: 7486, y: -57857, yaw: 30 },
    { x: 8796, y: -60126, yaw: 30 },
    { x: 10104, y: -57859, yaw: 30 },
    { x: 17956, y: -57861, yaw: 30 },
    { x: 17918, y: -53305, yaw: 240, half: true },
    { x: 15338, y: -62395, yaw: 30 },
    { x: 11413, y: -60127, yaw: 90 },
    { x: 10103, y: -62392, yaw: 90 },
    { x: 12721, y: -62394, yaw: 30 },
    { x: 15341, y: -53325, yaw: 30 },
    { x: 14032, y: -55593, yaw: 30 },
    { x: 15340, y: -57861, yaw: 30 },
    { x: 12722, y: -57860, yaw: 90 },
    { x: 16650, y: -55594, yaw: 30 },
    { x: 16649, y: -60129, yaw: 30 },
    { x: 12724, y: -53325, yaw: 30 },
    { x: 10105, y: -53321, yaw: 90 },
    { x: 8797, y: -55590, yaw: 30 },
    { x: 11415, y: -55591, yaw: 90 },
    { x: 51404, y: -27955, yaw: -60, half: true },
    { x: 50094, y: -30224, yaw: 0, half: true },
    { x: 50095, y: -34760, yaw: 0, half: true },
    { x: 51403, y: -37028, yaw: 60, half: true },
    { x: 55316, y: -39287, yaw: 60, half: true },
    { x: 57947, y: -39296, yaw: 120, half: true },
    { x: 61851, y: -37045, yaw: 120, half: true },
    { x: 63185, y: -34766, yaw: 180, half: true },
    { x: 57950, y: -25693, yaw: 240, half: true },
    { x: 55333, y: -25691, yaw: -60, half: true },
    { x: 63150, y: -30228, yaw: 180, half: true },
    { x: 57947, y: -34763, yaw: 30 },
    { x: 51403, y: -32492, yaw: 30 },
    { x: 52713, y: -34760, yaw: 30 },
    { x: 54021, y: -32493, yaw: 30 },
    { x: 61873, y: -32496, yaw: 30 },
    { x: 61835, y: -27939, yaw: 240, half: true },
    { x: 59255, y: -37029, yaw: 30 },
    { x: 55330, y: -34761, yaw: 90 },
    { x: 54020, y: -37026, yaw: 90 },
    { x: 56638, y: -37028, yaw: 30 },
    { x: 59258, y: -27959, yaw: 30 },
    { x: 57949, y: -30227, yaw: 30 },
    { x: 59257, y: -32495, yaw: 30 },
    { x: 56639, y: -32494, yaw: 90 },
    { x: 60567, y: -30228, yaw: 30 },
    { x: 60566, y: -34763, yaw: 30 },
    { x: 56641, y: -27959, yaw: 30 },
    { x: 54022, y: -27955, yaw: 90 },
    { x: 52714, y: -30224, yaw: 30 },
    { x: 55332, y: -30226, yaw: 90 },
    { x: 58729, y: -15250, yaw: -60, half: true },
    { x: 57419, y: -17518, yaw: 0, half: true },
    { x: 57420, y: -22055, yaw: 0, half: true },
    { x: 58728, y: -24322, yaw: 60, half: true },
    { x: 62641, y: -26582, yaw: 60, half: true },
    { x: 65272, y: -26590, yaw: 120, half: true },
    { x: 69177, y: -24339, yaw: 120, half: true },
    { x: 70510, y: -22060, yaw: 180, half: true },
    { x: 65275, y: -12987, yaw: 240, half: true },
    { x: 62658, y: -12985, yaw: -60, half: true },
    { x: 70476, y: -17522, yaw: 180, half: true },
    { x: 65273, y: -22057, yaw: 30 },
    { x: 58728, y: -19786, yaw: 30 },
    { x: 60038, y: -22054, yaw: 30 },
    { x: 61346, y: -19787, yaw: 30 },
    { x: 69198, y: -19790, yaw: 30 },
    { x: 69160, y: -15233, yaw: 240, half: true },
    { x: 66581, y: -24323, yaw: 30 },
    { x: 62655, y: -22056, yaw: 90 },
    { x: 61346, y: -24321, yaw: 90 },
    { x: 63963, y: -24322, yaw: 30 },
    { x: 66583, y: -15253, yaw: 30 },
    { x: 65274, y: -17521, yaw: 30 },
    { x: 66582, y: -19789, yaw: 30 },
    { x: 63964, y: -19789, yaw: 90 },
    { x: 67892, y: -17522, yaw: 30 },
    { x: 67891, y: -22057, yaw: 30 },
    { x: 63966, y: -15253, yaw: 30 },
    { x: 61347, y: -15250, yaw: 90 },
    { x: 60039, y: -17519, yaw: 30 },
    { x: 62657, y: -17520, yaw: 90 },
    { x: 63177, y: -4839, yaw: 180, half: true },
    { x: 61867, y: -2571, yaw: 240, half: true },
    { x: 57938, y: -303, yaw: 240, half: true },
    { x: 55320, y: -302, yaw: -60, half: true },
    { x: 51407, y: -2561, yaw: -60, half: true },
    { x: 50084, y: -4835, yaw: 0, half: true },
    { x: 50081, y: -9342, yaw: 0, half: true },
    { x: 51389, y: -11637, yaw: 60, half: true },
    { x: 61863, y: -11639, yaw: 120, half: true },
    { x: 63174, y: -9374, yaw: 180, half: true },
    { x: 55336, y: -13876, yaw: 60, half: true },
    { x: 54010, y: -7102, yaw: -90 },
    { x: 59249, y: -2570, yaw: -90 },
    { x: 56629, y: -2570, yaw: -90 },
    { x: 57938, y: -4837, yaw: -90 },
    { x: 54010, y: -11636, yaw: -90 },
    { x: 57976, y: -13881, yaw: 120, half: true },
    { x: 51393, y: -7102, yaw: -90 },
    { x: 55320, y: -4836, yaw: -30 },
    { x: 54013, y: -2570, yaw: -30 },
    { x: 52703, y: -4836, yaw: -90 },
    { x: 59247, y: -11639, yaw: -90 },
    { x: 57937, y: -9372, yaw: -90 },
    { x: 55319, y: -9370, yaw: -90 },
    { x: 56628, y: -7103, yaw: -30 },
    { x: 56628, y: -11638, yaw: -90 },
    { x: 52700, y: -9370, yaw: -90 },
    { x: 60555, y: -9373, yaw: -90 },
    { x: 61868, y: -7107, yaw: -30 },
    { x: 60557, y: -4839, yaw: -90 },
    { x: 59247, y: -7106, yaw: -30 },
    { x: 7478, y: 22819, yaw: -60, half: true },
    { x: 6169, y: 20551, yaw: 0, half: true },
    { x: 6169, y: 16014, yaw: 0, half: true },
    { x: 7477, y: 13746, yaw: 60, half: true },
    { x: 11390, y: 11487, yaw: 60, half: true },
    { x: 14022, y: 11479, yaw: 120, half: true },
    { x: 17926, y: 13729, yaw: 120, half: true },
    { x: 19260, y: 16009, yaw: 180, half: true },
    { x: 14025, y: 25082, yaw: 240, half: true },
    { x: 11408, y: 25084, yaw: -60, half: true },
    { x: 19225, y: 20546, yaw: 180, half: true },
    { x: 14022, y: 16012, yaw: 30 },
    { x: 7477, y: 18283, yaw: 30 },
    { x: 8787, y: 16014, yaw: 30 },
    { x: 10096, y: 18281, yaw: 30 },
    { x: 17948, y: 18279, yaw: 30 },
    { x: 17910, y: 22836, yaw: 240, half: true },
    { x: 15330, y: 13745, yaw: 30 },
    { x: 11405, y: 16013, yaw: 90 },
    { x: 10095, y: 13748, yaw: 90 },
    { x: 12713, y: 13746, yaw: 30 },
    { x: 15332, y: 22815, yaw: 30 },
    { x: 14024, y: 20547, yaw: 30 },
    { x: 15331, y: 18279, yaw: 30 },
    { x: 12714, y: 18280, yaw: 90 },
    { x: 16641, y: 20546, yaw: 30 },
    { x: 16641, y: 16011, yaw: 30 },
    { x: 12716, y: 22816, yaw: 30 },
    { x: 10097, y: 22819, yaw: 90 },
    { x: 8789, y: 20550, yaw: 30 },
    { x: 11406, y: 20549, yaw: 90 },
    { x: -7156, y: 22813, yaw: -60, half: true },
    { x: -8466, y: 20545, yaw: 0, half: true },
    { x: -8465, y: 16008, yaw: 0, half: true },
    { x: -7157, y: 13741, yaw: 60, half: true },
    { x: -3244, y: 11481, yaw: 60, half: true },
    { x: -613, y: 11473, yaw: 120, half: true },
    { x: 3292, y: 13724, yaw: 120, half: true },
    { x: 4625, y: 16003, yaw: 180, half: true },
    { x: -610, y: 25076, yaw: 240, half: true },
    { x: -3227, y: 25078, yaw: -60, half: true },
    { x: 4591, y: 20541, yaw: 180, half: true },
    { x: -612, y: 16006, yaw: 30 },
    { x: -7157, y: 18277, yaw: 30 },
    { x: -5847, y: 16009, yaw: 30 },
    { x: -4539, y: 18276, yaw: 30 },
    { x: 3313, y: 18273, yaw: 30 },
    { x: 3275, y: 22830, yaw: 240, half: true },
    { x: 696, y: 13740, yaw: 30 },
    { x: -3230, y: 16007, yaw: 90 },
    { x: -4539, y: 13742, yaw: 90 },
    { x: -1922, y: 13741, yaw: 30 },
    { x: 698, y: 22810, yaw: 30 },
    { x: -611, y: 20542, yaw: 30 },
    { x: 697, y: 18274, yaw: 30 },
    { x: -1921, y: 18274, yaw: 90 },
    { x: 2007, y: 20541, yaw: 30 },
    { x: 2006, y: 16006, yaw: 30 },
    { x: -1919, y: 22810, yaw: 30 },
    { x: -4538, y: 22813, yaw: 90 },
    { x: -5846, y: 20544, yaw: 30 },
    { x: -3228, y: 20543, yaw: 90 },
    { x: -14506, y: 10091, yaw: -60, half: true },
    { x: -15816, y: 7823, yaw: 0, half: true },
    { x: -15815, y: 3286, yaw: 0, half: true },
    { x: -14507, y: 1019, yaw: 60, half: true },
    { x: -10594, y: -1241, yaw: 60, half: true },
    { x: -7963, y: -1249, yaw: 120, half: true },
    { x: -4058, y: 1002, yaw: 120, half: true },
    { x: -2725, y: 3281, yaw: 180, half: true },
    { x: -7960, y: 12354, yaw: 240, half: true },
    { x: -10577, y: 12356, yaw: -60, half: true },
    { x: -2759, y: 7819, yaw: 180, half: true },
    { x: -7962, y: 3284, yaw: 30 },
    { x: -14507, y: 5555, yaw: 30 },
    { x: -13197, y: 3287, yaw: 30 },
    { x: -11889, y: 5554, yaw: 30 },
    { x: -4037, y: 5551, yaw: 30 },
    { x: -4075, y: 10108, yaw: 240, half: true },
    { x: -6654, y: 1018, yaw: 30 },
    { x: -10580, y: 3285, yaw: 90 },
    { x: -11889, y: 1020, yaw: 90 },
    { x: -9272, y: 1019, yaw: 30 },
    { x: -6652, y: 10088, yaw: 30 },
    { x: -7961, y: 7820, yaw: 30 },
    { x: -6653, y: 5552, yaw: 30 },
    { x: -9271, y: 5552, yaw: 90 },
    { x: -5343, y: 7819, yaw: 30 },
    { x: -5344, y: 3284, yaw: 30 },
    { x: -9269, y: 10088, yaw: 30 },
    { x: -11888, y: 10091, yaw: 90 },
    { x: -13196, y: 7822, yaw: 30 },
    { x: -10578, y: 7821, yaw: 90 },
]
